import styled from "styled-components";

interface StyledButtonProps {
  color?: string;
  borderRadius?: string;
  padding?: string;
  horizontalPadding?: string;
}

export const StyledButton = styled("button")<StyledButtonProps>`
  background: ${(p) => p.color || "#297EA3"}; /* Primary-400 */
  color: ${(p) => (p.color ? "#fff" : "#fff")}; /* Text color */
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: ${(p) => p.borderRadius || "4px"};
  padding: ${(p) => p.padding || "13px"} ${(p) => p.horizontalPadding || "0"};
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-left: 8px; /* Adjust the padding around the icon as needed */
    horizontal-padding: 13px;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #1F2223; /* Accent Coral Red */
    background-color: #1F2223; /* Accent Coral Red */
  }
`;